<template>
  <v-container fluid>
    <v-layout column>
      <v-flex xs12>
        <v-card class="px-5" color="#FAFAFA" style="margin-bottom:50px;">
          <v-layout row wrap align-center class="py-3">
            <v-autocomplete
              class="ma-2"
              v-model="searchParam.paper_code"
              :items="subWisePepList"
              :item-text="(item) => item.paper_code + ' - ' + item.paper_name"
              item-value="paper_code"
              label="Select Course"
              persistent-hint
              prepend-icon="library_books"
              :loading="loading"
            >
              <template v-slot:selection="data">
                <v-chip
                  color="#EBEBEB"
                  close
                  @input="searchParam.paper_code = ''"
                  :selected="data.selected"
                  class="chip--select-multi"
                  >{{
                    data.item.paper_code + " - " + data.item.paper_name
                  }}</v-chip
                >
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-tile-content v-text="data.item"></v-list-tile-content>
                </template>
                <template v-else>
                  <v-list-tile-content>
                    <v-list-tile-title
                      v-html="
                        data.item.paper_code + ' - ' + data.item.paper_name
                      "
                    ></v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </template>
            </v-autocomplete>
            <v-btn color="primary" fab round small @click="getCandiData()">
              <v-icon center dark>search</v-icon>
            </v-btn>
          </v-layout>
        </v-card>

        <v-card color="white">
          <v-layout row>
            <v-card flat>
              <v-badge left overlap>
                <template v-slot:badge>
                  <v-btn
                    round
                    color="primary"
                    fab
                    small
                    style="margin-top: -1.2em"
                    class="elevation-0"
                    >{{
                      pagination.totalItems ? pagination.totalItems : 0
                    }}</v-btn
                  >
                </template>
                <v-card-text class="title">Viva Candidate List</v-card-text>
              </v-badge>
            </v-card>
            <v-divider class="my-2" vertical></v-divider>
            <v-spacer />
            <v-btn
              :disabled="
                getExamActivityStatus.PracticalVivaThesisMarkSubmissionCompleted ==
                  false
              "
              @click="attandanceSheet()"
              >Attendence List</v-btn
            >
            <v-btn
              :disabled="
                getExamActivityStatus.PracticalVivaThesisMarkSubmissionCompleted ==
                  false
              "
              @click="PVstudentList()"
              >Download Candidate List</v-btn
            >
          </v-layout>
        </v-card>

        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="getPvCandidateDataSet.candidates"
          :loading="loading"
          :expand="expand"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :rows-per-page-items="pagination.rowsPerPageItems"
        >
          <template v-slot:items="props">
            <td class="text-xs">{{ props.index + 1 }}</td>
            <td class="text-xs">
              {{ props.item.paper_code }} - {{ props.item.paper_name }}
            </td>
            <td class="text-xs">{{ props.item.exam_roll_no }}</td>
            <td class="text-xs">{{ props.item.reg_no }}</td>
            <td class="text-xs">{{ props.item.ac_session }}</td>
            <td class="text-xs">{{ props.item.name }}</td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      dateIsBetween: false,
      searchParam: {},
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [5, 10, 25, { text: "All", value: -1 }],
      },
      getPracticalCandiList: [],
      expand: false,
      loading: false,
      headers: [
        { text: "SL", value: "sl" },
        { text: "Paper", value: "paper_code" },
        { text: "Exam Roll", value: "exam_roll" },
        { text: "Registration No", value: "reg_no" },
        { text: "Session Year", value: "session_year" },
        { text: "Name", value: "student_name" },
      ],
    };
  },
  watch: {
    pagination() {
      this.getCandiData();
    },
  },
  computed: {
    subWisePepList() {
      return this.$store.getters.getPvPepData;
    },
    profile() {
      return this.$store.getters.getCollegeUserProfile;
    },
    getPvCandidateDataSet() {
      return this.$store.getters.getPvCandidateData;
    },
    getExamActivityStatus() {
      return this.$store.getters.getExamActivityStatus;
    },
  },
  created() {
    this.getExamPrivData();
    this.getSubjectWisePepData();
    this.$store
      .dispatch("getExamActivityStatus", {
        exam_code: this.previlegedExam.exam_master.exam_code,
        exam_year: this.previlegedExam.exam_master.exam_year,
      })
      .then((data) => {
        console.log(data);
      });
  },
  mounted() {
    return this.up();
  },
  methods: {
    getExamPrivData() {
      this.previlegedExam = JSON.parse(
        localStorage.getItem("current-privilege")
      );
    },
    getSubjectWisePepData() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_type = "3";
      console.log("Tanvir! This is peram data", peram);
      this.$store.dispatch("fetchPvPepData", peram);
    },
    getCandiData() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.exam_year = this.previlegedExam.exam_master.exam_year;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_code = this.searchParam.paper_code;
      peram.college_code = this.profile.college_code;
      console.log("Tanvir! This is candi peram data", peram);
      if (peram.paper_code) {
        this.$store.dispatch("fetchPvCandidateData", peram).then((data) => {
          this.loading = false;
          this.sl_count = data.from;
          console.log("Tanvir, This is total", data.total_candidate);
          this.pagination.totalDataItems = data.total_candidate;
        });
      }
    },
    PVstudentList() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.exam_year = this.previlegedExam.exam_master.exam_year;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_code = this.searchParam.paper_code;
      peram.college_code = this.profile.college_code;
      peram.download_as = "pdf";

      if(this.searchParam.paper_code){
        this.$store.dispatch("PracticalVivaStudentList", peram);
      }
    },
    attandanceSheet() {
      let peram = {};
      peram.exam_code = this.previlegedExam.exam_master.exam_code;
      peram.exam_year = this.previlegedExam.exam_master.exam_year;
      peram.subject_code = this.previlegedExam.subject.subject_code;
      peram.paper_code = this.searchParam.paper_code;
      peram.college_code = this.profile.college_code;
      peram.download_as = "pdf";

      if(this.searchParam.paper_code){
        this.$store.dispatch("PracticalVivaStudentAttandance", peram);
      }
    },
  },
};
</script>
<style lang=""></style>
